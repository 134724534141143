.nav {
  grid-row: 1;
  grid-column: 1 / 4;
  background: white;
  box-shadow: 0 1px 1px -1px rgba(120, 120, 120, 0.6);
  display: flex;
  padding-left: 20px;
}

.nav img {
  max-height: 100%;
}

.nav span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}