.metronome {
  font-family:arial;
	font-size:24px;
	font-weight:bold;
	margin: 15px;
	grid-area: 2 / 2 / 2 / 2;
}

.play {
	-moz-box-shadow:inset 0px 20px 26px 0px #b6cde3;
	-webkit-box-shadow:inset 0px 20px 26px 0px #b6cde3;
	box-shadow:inset 0px 20px 26px 0px #b6cde3;
	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #79bbff), color-stop(1, #378de5) );
	background:-moz-linear-gradient( center top, #79bbff 5%, #378de5 100% );
	background-color:#79bbff;
	-moz-border-radius:20px;
	-webkit-border-radius:20px;
	border-radius:20px;
	color:#ffffff;
	padding:8px 24px;
	text-decoration:none;
	text-shadow:1px 1px 6px #000000;
	width: 80px;
	text-align: center;
}

.play:hover {
	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #378de5), color-stop(1, #79bbff) );
	background:-moz-linear-gradient( center top, #378de5 5%, #79bbff 100% );
	background-color:#378de5;
}

.play:active {
	position:relative;
	top:1px; left: 1px;
}