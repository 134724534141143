.setlist {
	max-height: 100%;
	grid-area: 2 / 1 / 2 / 2;
	border-right: 1px solid rgba(15, 15, 15, 0.2);
	overflow: scroll;
}

.playlist {
	width: 100%;
	user-select: none;
	padding: 0;
	margin: 0;
	border: 1px solid rgba(15, 15, 15, 0.2);
}

.playlist-title {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.playlist-item {
	height: 100px;
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: white;
}

html {
	overflow: scroll;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 0px;  /* remove scrollbar space */
	background: transparent;  /* optional: just make scrollbar invisible */
}

/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
	background: #FF0000;
}