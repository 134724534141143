.metro-options {
	height: 100%;
	grid-area: 2 / 3 / 2 / 4;
	border-left: 1px solid rgba(15, 15, 15, 0.2);
}

.accent-pattern {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 100px;
}

.accent-pattern > div {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}