@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto';
}

.App {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template: 50px 1fr / 400px 1fr 400px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.nav {
  grid-row: 1;
  grid-column: 1 / 4;
  background: white;
  box-shadow: 0 1px 1px -1px rgba(120, 120, 120, 0.6);
  display: flex;
  padding-left: 20px;
}

.nav img {
  max-height: 100%;
}

.nav span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}
.setlist {
	max-height: 100%;
	grid-area: 2 / 1 / 2 / 2;
	border-right: 1px solid rgba(15, 15, 15, 0.2);
	overflow: scroll;
}

.playlist {
	width: 100%;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	padding: 0;
	margin: 0;
	border: 1px solid rgba(15, 15, 15, 0.2);
}

.playlist-title {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.playlist-item {
	height: 100px;
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: white;
}

html {
	overflow: scroll;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 0px;  /* remove scrollbar space */
	background: transparent;  /* optional: just make scrollbar invisible */
}

/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
	background: #FF0000;
}
.metronome {
  font-family:arial;
	font-size:24px;
	font-weight:bold;
	margin: 15px;
	grid-area: 2 / 2 / 2 / 2;
}

.play {
	box-shadow:inset 0px 20px 26px 0px #b6cde3;
	background:-moz-linear-gradient( center top, #79bbff 5%, #378de5 100% );
	background-color:#79bbff;
	border-radius:20px;
	color:#ffffff;
	padding:8px 24px;
	text-decoration:none;
	text-shadow:1px 1px 6px #000000;
	width: 80px;
	text-align: center;
}

.play:hover {
	background:-moz-linear-gradient( center top, #378de5 5%, #79bbff 100% );
	background-color:#378de5;
}

.play:active {
	position:relative;
	top:1px; left: 1px;
}
.metro-options {
	height: 100%;
	grid-area: 2 / 3 / 2 / 4;
	border-left: 1px solid rgba(15, 15, 15, 0.2);
}

.accent-pattern {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 100px;
}

.accent-pattern > div {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
